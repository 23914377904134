import { DatePipe } from '@angular/common';
import {
  Inject,
  LOCALE_ID,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'unixDate',
  pure: false,
})
export class UnixDatePipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private readonly translate: TranslateService
  ) {
    super(locale);
  }
  transform(
    value: Date | string | number,
    format?: string,
    locale?: string,
    timezone?: string
  ): string | null;
  transform(
    value: null | undefined,
    format?: string,
    locale?: string,
    timezone?: string
  ): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    locale?: string,
    timezone?: string
  ): string | null;

  public transform(
    value: any,
    format?: string,
    locale?: string,
    timezone?: string
  ): string | null {
    const lang = this.translate.currentLang;

    const normalizedDate = moment.unix(value).utc().toDate();
    // const normalizedDate2 = moment.unix(value).tz('GMT').format();
    return super.transform(
      normalizedDate,
      lang === 'ru' && format && format.includes('MMMM d') ? format.replace('MMMM d', 'd MMMM') : format,
      timezone ? timezone:'GMT',
      locale ? locale : lang
    );
  }
}
