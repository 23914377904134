import {Language} from '@app-core/entities';

export const PHONE = '+7 (495) 730-87-07';
export const EMAIL = 'info@aviaoperator.com';

export const FOUNDATION_YEAR = 2007;
export const LANGUAGES_ALL: { [locale: string]: Language } = {
  ru: {
    locale: 'ru',
    flag: 'ru',
    name: 'Русский',
    code: 'ru-RU'
  },
  en: {
    locale: 'en',
    flag: 'gb',
    name: 'English',
    code: 'en-US'
  },
  ka: {
    locale: 'ka',
    flag: 'ge',
    name: 'ქართული',
    code:'ka-GE'
  },
}
export const LANGUAGES_RU: { [locale: string]: Language } = {
  ru: {
    locale: 'ru',
    flag: 'ru',
    name: 'Русский',
    code: 'ru-RU'
  },
  en: {
    locale: 'en',
    flag: 'gb',
    name: 'English',
    code: 'en-US'
  }

};
export const LANGUAGES_KA: { [locale: string]: Language } = {
  en: {
    locale: 'en',
    flag: 'gb',
    name: 'English',
    code: 'en-US'
  },
  ka: {
    locale: 'ka',
    flag: 'ge',
    name: 'ქართული',
    code:'ka-GE'
  },
  ru: {
    locale: 'ru',
    flag: 'ru',
    name: 'Русский',
    code: 'ru-RU'
  }
};
