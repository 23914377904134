import {AfterViewInit, Directive} from '@angular/core';
import { Calendar } from 'primeng/calendar';


@Directive({
  selector: '[appPrimeCalendarDateMask]'
})
export class PrimeCalendarDateMaskDirective implements AfterViewInit{

  constructor(private primeCalendar: Calendar) { }

  public ngAfterViewInit() {
    new Inputmask( this.getDateMask() ).mask( this.getHTMLInput() );
  }

  private getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }

  private getDateMask(): string {
    if(this.primeCalendar.timeOnly) {
      return '99:99';
    } else if(this.primeCalendar.showTime) {
      return '99.99.9999 99:99';
    } else {
      return '99.99.9999';
    }
  }

}
