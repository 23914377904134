import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent implements ControlValueAccessor, Validator {
  @Input() public label: string | undefined;
  @Input() public placeholder: string;

  public value: string | null;

  constructor() {
    this.value = null;
    this.placeholder = '';
  }
  public validate(control: AbstractControl): ValidationErrors | null {
    const value: string | null = control.value;
    return !value || value.includes('_') ? { controlRequired: true } : null;
  }
  public registerOnValidatorChange(fn: () => void) {
    this.onValidationChange = fn;
  }

  private onTouched = () => {};
  private onChange: (value: string | null | undefined) => void = () => {};
  private onValidationChange: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(value: string | null): void {
    const normalizedValue = '+' + (value ? value.replace(/[^0-9-]/g, '') : '');

    this.value = normalizedValue;
    this.onChange(normalizedValue);
  }

  public onChangeInput(event: Event) {
    const normalizedValue = this.#ensurePlusPrefix(this.value);
    this.value = normalizedValue;
    this.onChange(normalizedValue);
  }

  public onKeyUp = (event: KeyboardEvent) => {
    const normalizedValue = this.#ensurePlusPrefix(this.value);
    this.value = normalizedValue;
    this.onChange(normalizedValue);
  };

  #ensurePlusPrefix(str: string | null): string {
    return str ? (str.startsWith('+') ? str : '+' + str) : '+';
  }
}
