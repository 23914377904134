import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-touroperators-list',
  templateUrl: './touroperators-list.component.html',
  styleUrls: ['./touroperators-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TouroperatorsListComponent implements OnInit {
  public coral: string = '/assets/images/to/coral.png';
  public biblio: string = '/assets/images/to/biblio.png';
  public anex: string = '/assets/images/to/anex.png';
  public paks: string = '/assets/images/to/paks.png';
  public pegas: string = '/assets/images/to/pegas.png';
  public tui: string = '/assets/images/to/tui.png';
  public teztour_logo: string = '/assets/images/to/teztour_logo.png';
  constructor() {}

  public ngOnInit = (): void => {};
}
