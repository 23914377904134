import {OrderStatusEnum} from "@app-core/enums";

export const ORDER_STATUSES: Record<OrderStatusEnum, string> = {
  [OrderStatusEnum.New]:'PAGE.PROFILE.STATUSES.NEW',
  [OrderStatusEnum.Booking]:'PAGE.PROFILE.STATUSES.BOOKING',
  [OrderStatusEnum.Canceled]:'PAGE.PROFILE.STATUSES.CANCELED',
  [OrderStatusEnum.Ready]:'PAGE.PROFILE.STATUSES.READY',
  [OrderStatusEnum.Confirmed]:'PAGE.PROFILE.STATUSES.CONFIRMED',
  [OrderStatusEnum.ClientRefused]:'PAGE.PROFILE.STATUSES.CLIENT_REFUSED',
  [OrderStatusEnum.TORefused]:'PAGE.PROFILE.STATUSES.OPERATOR_REFUSED',
};
