import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appEmptyToNull]',
})
export class EmptyToNullDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('blur', ['$event']) onKeyDowns(event: KeyboardEvent) {
    if (this.ngControl.value?.trim() === '') {
      this.ngControl.reset(null);
    }
  }
}
