<ng-container *ngIf="ticket">
  <div
    class="ticket-wrapper"
    [class.no-price-block]="!showPrice"
    [class.ow]="!ticket.returnFlight"
  >
    <div class="direct-wrapper" *ngIf="ticket.directFlight">
      <ng-container
        *ngTemplateOutlet="
          flightBlock;
          context: {
            flight: ticket.directFlight,
            handLuggage: ticket.price.luggage.handLuggage,
            compartmentLuggage: ticket.price.luggage.compartmentLuggage
          }
        "
      ></ng-container>
    </div>
    <div class="return-wrapper" *ngIf="ticket.returnFlight">
      <ng-container
        *ngTemplateOutlet="
          flightBlock;
          context: {
            flight: ticket.returnFlight,
             handLuggage: ticket.price.luggage.returnHandLuggage,
            compartmentLuggage: ticket.price.luggage.returnCompartmentLuggage
          }
        "
      ></ng-container>
    </div>
    <div class="payment-wrapper" *ngIf="ticket.price && showPrice">
      <ng-container
        *ngTemplateOutlet="
          priceBlock;
          context: {
            price: ticket.price
          }
        "
      ></ng-container>
    </div>
    <!--    <div class="tag tag&#45;&#45;business" *ngIf="ticket.price ">-->
    <div
      class="tag tag--business"
      *ngIf="ticket.price && ticket.price.isBusiness"
    >
      {{ "PAGE.GLOBAL.FLIGHT_CLASSES.BUSINESS" | translate }}
    </div>
  </div>
  <ng-template #flightBlock let-flight="flight" let-compartmentLuggage="compartmentLuggage" let-handLuggage="handLuggage" >
    <div class="flight-main">
      <div class="flight-takeoff">
        <div>
          <span><img [src]="airplaneTakeoff" /></span>
          <span class="big-bold-font">{{
            flight.takeoffDateTime | unixDate : "HH:mm"
          }}</span>
          <span class="iata">{{ flight.airportFromIata }}</span>
        </div>
        <div>{{ flight.takeoffDateTime | unixDate : "MMMM d, EEEE" }}</div>
        <div>{{ flight.cityFromName }}</div>
      </div>
      <div class="flight-landing">
        <div>
          <span><img [src]="airplaneLanding" /></span>
          <span class="big-bold-font">{{
            flight.landingDateTime | unixDate : "HH:mm"
          }}</span>
          <span class="iata">{{ flight.airportToIata }}</span>
        </div>
        <div class="white-space-nowrap">
          <span class="relative">
            {{ flight.landingDateTime | unixDate : "MMMM d, EEEE" }}
            <span
              *ngIf="
                isMoreOneDay(
                  flight.takeoffDateTime,
                  flight.landingDateTime
                ) as days
              "
              class="tag tag--day"
              >{{
                "UI.VALUES.DAYS.1"
                  | translate : { days: "+" + days }
                  | lowercase
              }}</span
            >
          </span>
        </div>
        <div>{{ flight.cityToName }}</div>
      </div>
    </div>
    <div class="flight-name">

      <div class="m-0 luggage flex flex-center" *ngIf="ticket.price">
        <span class="flex flex-center">
          <img [src]="suitcase" />
          {{ compartmentLuggage }}
          {{ "PAGE.GLOBAL.OTHER.KG" | translate }}
        </span>
        <span class="ml-3 flex flex-center">
          <img [src]="handbag" />
          {{ handLuggage }}
          {{ "PAGE.GLOBAL.OTHER.KG" | translate }}
        </span>
      </div>
      <span>
          {{ "PAGE.SEARCH.TICKET_CARD.FLIGHT_FIELD" | translate }}
        {{ flight.flightName }}
        {{ flight.aircompanyName }}
      </span>
    </div>
  </ng-template>
  <ng-template #priceBlock let-price="price">
    <div>
      <div class=" big-bold-font" *ngIf="price.totalSum">
        <ng-container *isFull="true;else notRUB" >
          {{ {EUR: 0, GEL: 0, RUB: price.totalSum.rub, USD: 0 } | siteCurrency }}
        </ng-container>
        <ng-template #notRUB>
            {{ {EUR: 0, GEL: price.totalSum.ka, RUB: 0, USD: price.totalSum.usd } | siteCurrency }}
        </ng-template>

      </div>
<!--      <div class="m-2 luggage flex flex-center">-->
<!--        <span class="flex flex-center">-->
<!--          <img [src]="suitcase" />-->
<!--          {{ price.luggage.compartmentLuggage }}-->
<!--          {{ "PAGE.GLOBAL.OTHER.KG" | translate }}-->
<!--        </span>-->
<!--        <span class="ml-3 flex flex-center">-->
<!--          <img [src]="handbag" />-->
<!--          {{ price.luggage.handLuggage }}-->
<!--          {{ "PAGE.GLOBAL.OTHER.KG" | translate }}-->
<!--        </span>-->
<!--      </div>-->
      <div class=" buy-btn-wrapper" *ngIf="showBtn">
        <button
          class="btn-primary buy-btn"
          [routerLink]="[variantLink]"
        >
          {{ "PAGE.SEARCH.TICKET_CARD.BUY" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
