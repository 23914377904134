export enum PassengerDocumentType {
  //[Display(Name = "Загранпаспорт")]
  InternationalPassport = 1,

  //[Display(Name = "Внутренний паспорт")]
  InternalPassport = 2,

  //[Display(Name = "Св-во о рождении")]
  BirthCertificate = 3,
}
