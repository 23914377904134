import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {Ticket} from '@app-core/models';
import moment from "moment/moment";

const airplane_takeoff = '/assets/images/airplane-takeoff.svg';
const airplane_landing = '/assets/images/airplane-landing.svg';
const suitcase = '/assets/images/suitcase_icon.svg';
const handbag = '/assets/images/handbag.svg';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCardComponent implements OnInit {
  public airplaneTakeoff = airplane_takeoff;
  public airplaneLanding = airplane_landing;
  public suitcase = suitcase;
  public handbag = handbag;
  @Input() public ticket!: Ticket;
  @Input() public showPrice: boolean;
  @Input() public showBtn: boolean;
  constructor() {
    this.showPrice = true;
    this.showBtn = true;
  }
  public get variantLink(): string {
    return `/booking/${this.ticket.variantId}/${this.ticket.requestId}`;
  }
  public ngOnInit(): void {}
  public isMoreOneDay(firstDate: number, secondDate: number):number{
    const first = moment.unix(firstDate).utc();
    const second = moment.unix(secondDate).utc();
    return second.isAfter(first,'day') ? Math.ceil(second.diff(first,'days',true)):0
  }
}
