import {ChangeDetectionStrategy, Component} from '@angular/core';

const cat = '/assets/images/cat.svg';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  public cat = cat;


}
