<p-dropdown
  [options]="options"
  [ngModel]="currentLanguage.locale"
  [styleClass]="'p-dropdown--custom'"
  (onChange)="changeLang($event)"
  optionLabel="name"
  optionValue="locale"
  [showClear]="false"
>
  <ng-template pTemplate="selectedItem">
    <div class="country-item country-item-value" *ngIf="currentLanguage">
      <span
        [class]="'fi fi-' + currentLanguage.flag.toLowerCase()"
      ></span>
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="country-item flex">
      <span
        [class]="'item-flag fi fi-' + country.flag.toLowerCase()"
      ></span>
      <div>{{ country.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
