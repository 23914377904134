import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {LocalStorageModel} from './local-storage.model';

@Injectable()
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}
  public get = <K extends keyof LocalStorageModel>(
    key: K
  ): LocalStorageModel[K] => {
    return this.storage.get(key);
  };
  public set = <T extends LocalStorageModel, K extends keyof LocalStorageModel>(
    key: K,
    value: T[K]
  ) => {
    this.storage.set(key, value);
  };
  public remove = (key: keyof LocalStorageModel) => {
    this.storage.remove(key);
  };

  public update = < K extends keyof LocalStorageModel>(
    key: K,
    callback: (value: LocalStorageModel[K]) => LocalStorageModel[K]
  ) => {
    const value = callback(this.get(key));
    this.set(key, value);
  }
}
