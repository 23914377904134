import {CurrencyEnum} from "@app-core/enums/currency.enum";
import {BaseModel} from "@app-core/models";


export interface ICurrencySite extends BaseModel<CurrencyEnum,string>{
   siteList: string[];
   locale: string;
}
 export const CurrencyList: ICurrencySite[]  = [
   {
     value: CurrencyEnum.USD,
     label: '$ USD',
     siteList: ['KA'],
     locale: 'en-US'
   },
    {
      value: CurrencyEnum.EUR,
      label: '€ EUR',
      siteList: [],
      locale: 'en-US'
    },
    {
      value: CurrencyEnum.RUB,
      label: '₽ RUB',
      siteList: ['RU'],
      locale: 'ru'
    },
    {
      value: CurrencyEnum.GEL,
      label: '₾ GEL',
      siteList: ['KA'],
      locale: 'ka'
    }
 ]
