// взять с бэка
export enum PaymentCurrency {
  KA = 20,
  USD = 3,
  RUB = 1,
}
export enum CurrencyEnum {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  GEL = 'GEL',
}
