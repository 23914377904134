<p-dialog
  [visible]="visible"
  (visibleChange)="visibleChanged($event)"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '50vw' }"
  [showHeader]="false"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
>
  <div class="onboarding-banner relative">
    <i *ngIf="closeBtn" class="fas fa-times closeButton" (click)="onClose()"></i>
    <div class="flex flex-column justify-content-center align-items-center px-4 pt-4 pb-2">
        <ng-content></ng-content>
        <button (click)="onClose()">
          <ng-content select="[btn]"></ng-content>
        </button>
    </div>
  </div>
</p-dialog>
