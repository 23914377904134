import { CurrencyList, ICurrencySite, LANGUAGES_ALL} from "@app-core/constants";
import {Language} from "@app-core/entities";
import {CurrencyEnum} from "@app-core/enums/currency.enum";

export interface LayoutStateModel {
  userForm: {
    model: any;
    dirty: boolean;
    status: string;
    errors: object;
  };
  header: { header?:string,subheader?:string };
  isHomePage: boolean;
  language: Language;
  currency: CurrencyEnum;
  currencyList: ICurrencySite[];
}

export const DEFAULT_LAYOUT_STATE_MODEL: LayoutStateModel = {
  userForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  header: {  },
  language:LANGUAGES_ALL['en'],
  isHomePage: false,
  currency: CurrencyEnum.USD,
  currencyList:CurrencyList
};
