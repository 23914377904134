export enum DIRECTION {
  from = 0,
  to = 1,
}

export enum CLASS_TYPE {
  economy = 0,
  business = 1,
}

export enum SEARCH_FORM_TYPE {
  oneDirection = 0,
  twoDirections = 1,
}
