import {BaseModel} from "@app-core/models";
import {PassengerDocumentType} from "@app-core/enums";

export const DOCUMENT_TYPES: BaseModel<PassengerDocumentType>[] = [
  {
    label: 'UI.VALUES.PASSPORT',
    value: PassengerDocumentType.InternalPassport,
  },
  {
    label: 'UI.VALUES.FOREIGN_PASSPORT',
    value: PassengerDocumentType.InternationalPassport,
  },
  {
    label: 'UI.VALUES.BIRTH_CERTIFICATE',
    value: PassengerDocumentType.BirthCertificate,
  },
];
export const RUSSIAN_NATIONAL = 1;
export const RUSSIAN={
  value: 267,
  label: 'Россия'
}
export const FRIENDLY_COUNTRIES: BaseModel<number>[] = [
  {
    value: 160,
    label: 'Абхазия',
  },
  {
    value: 170,
    label: 'Армения',
  },
  {
    value: 177,
    label:'Беларусь'
  },
  {
    value: 216,
    label: 'Казахстан',
  },
  {
    value: 229,
    label: 'Кыргызстан',
  },

];
