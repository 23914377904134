import {InjectionToken} from "@angular/core";
import {MainDictionary} from "@app-core/entities";
import {Observable} from "rxjs";

export const IS_FULL_VERSION_TOKEN = new InjectionToken('IS_FULL_VERSION');
export const LANGUAGES_TOKEN = new InjectionToken('LANGUAGES_TOKEN');
export const RESOURCES_SERVICE = new InjectionToken('RESOURCES_SERVICE');

export interface IResourcesService {
  getAllDictionaries(): Observable<MainDictionary>
}
