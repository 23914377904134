import {
  Directive,
  ElementRef, Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {IS_FULL_VERSION_TOKEN} from "@app-core/constants";
import { Language } from '@app-core/entities';
import { LayoutStateService } from '@app-states/layout-state.service';
import { Observable } from 'rxjs';

@Directive({
  selector: '[isFull]',
})
export class IsFullVersionDirective implements OnInit {
  private value:boolean=true;
  @Input() set isFull(val: boolean) {
    this.value = val;
    // this.updateView(val);
  }

  @Input() isFullElse?: TemplateRef<unknown>;
  constructor(
    @Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly vcr: ViewContainerRef,
  ) {

  }

  ngOnInit(): void {
    this.updateView(this.value);
  }

  private updateView(val: boolean) {
    this.vcr.clear();
    if (this.isFullToken && val) {
      this.vcr.createEmbeddedView(this.templateRef);
    } else if (this.isFullElse) {
      this.vcr.createEmbeddedView(this.isFullElse);
    } else {
      this.vcr.clear();
    }
  }
}
