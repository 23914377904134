<!--<div class="grid align-items-center justify-content-center ">-->
<!--  <strong class=" white-space-nowrap" style="width: fit-content"-->
<!--    >{{'PAGE.CONTACTS.WORKING_MODE' | translate}}-->
<!--  </strong>-->
<!--  <label class=" white-space-nowrap" style="width: fit-content"-->
<!--    >{{'PAGE.CONTACTS.MON_FRI' |translate}} 10:00–19:00,</label-->
<!--  >-->
<!--  <label class=" white-space-nowrap" style="width: fit-content"-->
<!--    >{{'PAGE.CONTACTS.SAT' | translate}} 11:00–17:00,</label-->
<!--  >-->
<!--  <label class=" white-space-nowrap" style="width: fit-content"-->
<!--    >{{'PAGE.CONTACTS.SUN'|translate}} {{'PAGE.CONTACTS.DAY_OFF'|translate}}</label-->
<!--  >-->
<!--</div>-->
<div class="message message-info" [class]="'Warning'" >
  <div class="message-wrapper">
    <i class="pi pi-info-circle" ></i>
    <div class="flex flex-wrap gap-3">
        <strong class=" white-space-nowrap" style="width: fit-content"
          >{{'PAGE.CONTACTS.WORKING_MODE' | translate}}
        </strong>
      <ng-container *isFull="true;else kaBlock">
        <label class=" white-space-nowrap" style="width: fit-content"
        >{{'PAGE.CONTACTS.DAYS' | translate}} </label
        >
      </ng-container>
      <ng-template #kaBlock>
        <label class=" white-space-nowrap" style="width: fit-content"
        >{{'PAGE.CONTACTS.DAYS' | translate}} </label
        >
      </ng-template>
    </div>
  </div>
</div>
