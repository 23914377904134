export const itemsSocialNetwork = [
  {
    label: 'UI.FOOTER.VB',
    icon: 'fab fa-viber',
    url: 'https://viber.com/aviaoperator',
  },
  {
    label: 'UI.FOOTER.VK',
    icon: 'fab fa-vk',
    url: 'https://vk.com/clubaviaoperator',
  },
  // {
  //   label: 'UI.FOOTER.TG_BOT',
  //   icon: 'fab fa-telegram-plane',
  //   url: 'https://t.me/aviaoperator_bot',
  // },
];
