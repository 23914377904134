import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import { AnnouncementComponent } from './announcement.component';
import { AnnouncementCardComponent } from './announcement-card/announcement-card.component';



@NgModule({
  declarations: [
    AnnouncementComponent,
    AnnouncementCardComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [AnnouncementComponent,AnnouncementCardComponent]
})
export class AnnouncementModule { }
