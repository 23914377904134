import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {AnnouncementTheme} from '@app-core/enums';
import {Announcement} from '@app-core/models';
import {TranslateService} from '@ngx-translate/core';
import {notNull} from '@shared/utils';
import {BehaviorSubject, distinctUntilKeyChanged, merge, Observable, of, switchMap,} from 'rxjs';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementComponent implements OnInit {
  public get item(): Announcement | undefined {
    return this._item;
  }
  @Input() set item(value: Announcement | undefined) {
    this._item = value;
    const currentLang = this.translate.currentLang;
    this.textSubject$.next(
      (currentLang === 'ru' ? value?.text : value?.enText) || ''
    );
  }
  private _item: Announcement | undefined;
  public text$: Observable<string | undefined>;
  public textSubject$: BehaviorSubject<string>;

  constructor(private readonly translate: TranslateService) {
    this.textSubject$ = new BehaviorSubject<string>('');
    //TODO возможно если будет много языков переделать и вынести в стейт
    this.text$ = merge(
      this.textSubject$,
      this.translate.onLangChange.pipe(
        distinctUntilKeyChanged('lang'),
        switchMap((value) =>
          value.lang === 'ru' ? of(this._item?.text) : of(this._item?.enText)
        )
      )
    );
  }

  public ngOnInit(): void {}

  public getAnnouncementThemeName = (theme: AnnouncementTheme):string =>
    notNull(theme) ? AnnouncementTheme[theme] : 'Warning';
}
