import {Inject, Injectable} from '@angular/core';
import {CurrencyList, ICurrencySite, IS_FULL_VERSION_TOKEN, LANGUAGES_TOKEN} from '@app-core/constants';
import {Language} from '@app-core/entities';
import {LocalStorageService} from '@app-core/local-storage-service/local-storage.service';
import {TranslateService} from '@ngx-translate/core';
import {RouterNavigation} from '@ngxs/router-plugin';
import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {DEFAULT_LAYOUT_STATE_MODEL, LayoutStateModel,} from './layout-state.model';
import {ChangeCurrency, ChangeHeader, ChangeLanguage} from './layout.actions';
import {ActivatedRoute, RouterStateSnapshot} from '@angular/router';
import {CurrencyEnum} from "@app-core/enums/currency.enum";

@State<LayoutStateModel>({
  name: 'layout',
  defaults: DEFAULT_LAYOUT_STATE_MODEL,
})
@Injectable()
export class LayoutState implements NgxsOnInit {
  @Selector()
  public static header({ header }: LayoutStateModel): {
    header?: string;
    subheader?: string;
  } {
    return header;
  }
  @Selector()
  public static isHomePage({ isHomePage }: LayoutStateModel): boolean {
    return isHomePage;
  }
  @Selector()
  public static language({ language }: LayoutStateModel): Language {
    return language;
  }
  @Selector()
  public static currency({ currency }: LayoutStateModel): CurrencyEnum {
    return currency;
  }
  @Selector()
  public static currencyList({
    currencyList,
  }: LayoutStateModel): ICurrencySite[] {
    return currencyList;
  }
  constructor(
    @Inject(LANGUAGES_TOKEN) private languages: { [locale: string]: Language },
    @Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,
    private readonly translate: TranslateService,
    private readonly storage: LocalStorageService,
    private readonly route: ActivatedRoute
  ) {}
  public ngxsOnInit({ dispatch }: StateContext<LayoutStateModel>): void {
    dispatch(new ChangeLanguage(this.selectLanguage()));
    const initialCurrency = this.isFullToken ? CurrencyEnum.RUB : CurrencyEnum.USD;
    const currency = this.findCurrency(initialCurrency,this.isFullToken ? 'RU' : 'KA');
    if (currency) {
      dispatch(new ChangeCurrency(currency.value));
    }
    this.route.queryParams.subscribe((params) => {
      const lang: string | undefined = params['lang'] || params['locale'];
      const currencyParams: string | undefined = params['currency']?.toUpperCase();
      if (currencyParams) {
        const currency = this.findCurrency(currencyParams as CurrencyEnum,this.isFullToken ? 'RU' : 'KA');
        if (currency) {
          dispatch(new ChangeCurrency(currency.value));
        }
      }
      if (lang) {
        const language = this.findLanguage(lang);
        if (language) {
          dispatch(new ChangeLanguage(language));
        }
      }
    });
  }
  private selectLanguage(): Language {
    const language = this.getLangFromStorage();
    const defaultValue = this.getDefaultValue();
    return language
      ? language
      : defaultValue
      ? defaultValue
      : this.languages['en'];
  }
  private findCurrency(currency: CurrencyEnum, site: 'RU'|'KA'): ICurrencySite | undefined {
    return CurrencyList.find((item) => item.value === currency && item.siteList.includes(site)) ;
  }
  private findLanguage(lang: string | undefined): Language | undefined {
    if (!lang) return undefined;
    return this.languages[lang];
  }
  private getLangFromStorage(): Language | undefined {
    const storageVal = this.storage.get('language');
    return this.findLanguage(storageVal?.locale);
  }

  private getBrowserLanguage(): string | undefined {
    return this.translate.getBrowserLang();
  }
  private getDefaultValue(): Language | undefined {
    const browserLang: string | undefined = this.getBrowserLanguage();
    return this.findLanguage(browserLang);
  }
  @Action(ChangeHeader)
  public changeHeader(
    { patchState }: StateContext<LayoutStateModel>,
    { payload }: ChangeHeader
  ) {
    patchState({ header: payload });
  }
  @Action(ChangeLanguage, { cancelUncompleted: true })
  public changeLanguage(
    { patchState }: StateContext<LayoutStateModel>,
    { language }: ChangeLanguage
  ) {
    this.translate.use(language.locale);
    this.storage.set('language', language);
    patchState({ language: language });
  }
  @Action(ChangeCurrency, { cancelUncompleted: true })
  public changeCurrency(
    { patchState }: StateContext<LayoutStateModel>,
    { currency }: ChangeCurrency
  ) {
    patchState({ currency });
  }
  @Action(RouterNavigation<RouterStateSnapshot>)
  public navigation(
    { patchState }: StateContext<LayoutStateModel>,
    { routerState }: RouterNavigation
  ) {
    patchState({
      isHomePage:
        routerState.url === '/' || routerState.url.includes('presearch'),
    });
  }
}
