import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-announcement-card',
  templateUrl: './announcement-card.component.html',
  styleUrls: ['./announcement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementCardComponent {
@Input() text: string | null|undefined;
@Input() theme: string ;
constructor() {
this.text ='';
this.theme ='Warning'
}
}
