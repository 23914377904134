import { phoneMaskList } from '@app-core/constants';
import { Airport, AirportExtended } from '@app-core/entities';
import { CLASS_TYPE, SortingOrder } from '@app-core/enums';
import {
  ExternalSearchForm,
  PreSearchForm,
  Sorting,
  SpecialSortings,
} from '@app-core/models';
import { PresetAirports } from '@app-states/search/search-state.model';
import {createMask, InputmaskOptions} from '@ngneat/input-mask';
import moment from 'moment';

/**
 * Осуществляет сортировку
 * @param items Исходный массив
 * @param soring Информация о сортировки
 * @param specialSortings Информация о специальных сортировках
 */
export function sort<TSource>(
  items: TSource[],
  sorting: Sorting,
  specialSortings?: SpecialSortings<TSource>
): TSource[] {
  if (items.length === 0) {
    return items;
  }

  if (specialSortings !== undefined && sorting.field in specialSortings) {
    return specialSortings[sorting.field](items, sorting);
  }

  if (typeof (items[0] as any)[sorting.field] === 'number') {
    return items.sort((first: any, second: any) =>
      sorting.order === SortingOrder.Asc
        ? first[sorting.field] - second[sorting.field]
        : second[sorting.field] - first[sorting.field]
    );
  }
  if (typeof (items[0] as any)[sorting.field] === 'string') {
    return items.sort((first: any, second: any) =>
      sorting.order === SortingOrder.Asc
        ? first[sorting.field] &&
          first[sorting.field].localeCompare(second[sorting.field])
        : second[sorting.field] &&
          second[sorting.field].localeCompare(first[sorting.field])
    );
  }
  return items;
}
const entityFind = <T extends Record<string, unknown>, K extends keyof T>(
  entities: T[],
  value: any,
  field: K
) => entities.find((entity) => entity[field] === value)!;
// export function searchInTree(tree: any, value: any, key = 'id', reverse = false) {
//   console.log(value);

//   const stack = [tree];
//   while (stack.length) {
//     const node = stack[reverse ? 'pop' : 'shift']();
//     if (node[key].toLowerCase().includes(value.toLowerCase())) return node;
//     node.children && stack.push(...node.children);
//   }
//   return null;
// }
const sumFormatter = new Intl.NumberFormat('ru-RU');

export const formatSum = (sum: number): string =>
  sum ? sumFormatter.format(sum) : '0';

export function dateUnixToStringConverter(
  unixTS: number,
  format: string = 'DD.MM.YYYY'
): string {
  return moment.unix(unixTS).utc().format(format);
}
export const notNull = (value: any): boolean =>
  value !== null && value !== undefined;

export const emailInputMask = createMask({ alias: 'email' });

export const detectCodeFromPhone = (value: string): string => {
  let code = '+7';
  phoneMaskList.forEach((item) => {
    const tmpCode = item.code.replace(/[\s#]/g, ''),
      phone = value.replace(/[\s#-)(]/g, '');

    if (phone.includes(tmpCode)) {
      code = tmpCode;
    }
  });
  // console.log(code)
  return code;
};
export const phoneInputMask = (countryCode: string, value: string = '') => {
  // console.log(123,value)
  // TODO на основе этого кода можно подменить код страны при ее смене
  function setMask() {
    let matrix = '+###############';

    phoneMaskList.forEach((item) => {
      let code = item.code.replace(/[\s#]/g, ''),
        phone = value.replace(/[\s#-)(]/g, '');

      if (phone.includes(code)) {
        // console.log(phone, code);
        matrix = item.code;
      }
    });

    let i = 0,
      val = value.replace(/\D/g, '');

    value = matrix.replace(/(?!\+)./g, function (a) {
      return /[#\d]/.test(a) && i < val.length
        ? val.charAt(i++)
        : i >= val.length
        ? ''
        : a;
    });
  }
  // setMask()
  const newMask = phoneMaskList.find((el) => el.code.includes(countryCode));
  return createMask({
    mask: newMask ? newMask.code.replace(/9/g, '\\9') : '+###############',
    placeholder: newMask
      ? newMask.code.replace(/[\s#]/g, '_')
      : '+_______________',
    definitions: {
      '#': {
        validator: '[0-9\uFF11-\uFF19]',
      },
    },
    noValuePatching: false,
  });
};

export const toHash = (
  infantCount: number,
  classType: CLASS_TYPE,
  adultCount: number,
  airportFrom: string | AirportExtended,
  airportTo: string | AirportExtended,
  returnDate: string | Date | null,
  departDate: string | Date | null
): string => {
  // search/MOW_AYT_2022.12.17_2022.12.24_1_2_3
  // москва-анталия
  // туда 2022.12.17
  // обратно 2022.12.24
  // 1 - эконом, 2 - бизнес
  // взрослых/детей 2
  // инфантов 3
  // -------------------------------------------
  //   search/MOW_AYT_2022.12.17__1_2_3
  // москва-анталия
  // туда 2022.12.17
  // 1 - эконом, 2 - бизнес
  // взрослых/детей 2
  // инфантов 3
  const format: string = 'DD.MM.YYYY';
  return `${
    typeof airportFrom !== 'string' ? airportFrom.airport.iata : airportFrom
  }_${
    typeof airportTo !== 'string' ? airportTo.airport.iata : airportTo
  }_${moment(departDate).format(format)}_${
    returnDate ? moment(returnDate).format(format) : ''
  }_${classType}_${adultCount}_${infantCount}`;
};

/** MOW_AYT_2022.12.17_2022.12.24_1_2_3 или MOW_AYT_2022.12.17__1_2_3 */
export const fromHash = (value: string, airports: Airport[]): PreSearchForm => {
  // ['AYT', 'MOW', '20.12.2022', '', '0', '2', '0']
  value = value.split('?')[0];
  const arrValues = value.split('_');
  const airportFrom = airports.find((value) => value.iata === arrValues[0]);
  const airportTo = airports.find((value) => value.iata === arrValues[1]);

  return {
    adultCount: +arrValues[5],
    airportFromId: airportFrom ? airportFrom.id : PresetAirports.From,
    airportToId: airportTo ? airportTo.id : PresetAirports.To,
    classType: arrValues[4] === '0' ? CLASS_TYPE.economy : CLASS_TYPE.business,
    departDate: arrValues[2],
    infantCount: +arrValues[6],
    returnDate: arrValues[3].length ? arrValues[3] : null,
  };
};
export const toCamel = (o: object) => {
  let newO: any;
  let origKey, newKey: string;
  let value: any;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey as keyof typeof o];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        // if (
        //   typeof value === "string" && value.includes('')
        // ) {
        //   value = toCamel(JSON.parse(value));
        // }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};
export const dateInputMask = (options?: InputmaskOptions<Date>) => createMask<Date>({
  alias: 'datetime',
  inputFormat: 'dd.mm.yyyy',
  parser: (value: string) => {
    console.log(value);
    const values = value.split('.');
    const year = +values[2];
    const month = +values[1] - 1;
    const date = +values[0];
    return new Date(year, month, date);
  },
  ...options,
});

export const delay = (ms:number): Promise<void> =>{
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const  retryPromise= async <T>(fn: () => Promise<T>, maxRetries: number, delayTime: number): Promise<T> => {
  try {
    return await fn();
  } catch (err) {
    if (maxRetries <= 0) {
      throw err;
    }
    await delay(delayTime);
    return await retryPromise(fn, maxRetries - 1, delayTime);
  }
}
export const validateMomentDate = (dateString: string|null,dateFormat:string): moment.Moment | null=> {
  if (!dateString) {
    return null;
  }
  const momentDate = moment(dateString,dateFormat);
  return momentDate.isValid() ? momentDate : null;
}

export const getMinReturnDate = (departDate: Date): Date => {
  return moment(departDate).add(1, 'days').toDate();
}
export const getStandardReturnDate = (departDate: Date): Date => {
  return moment(departDate).add(7, 'days').toDate();
}
