<div class="grid">
  <div class="col-12">
    <h2 class="header">{{ "PAGE.GLOBAL.WHY.TITLE" | translate }}</h2>
  </div>
  <div class="col-12 sm:col-6  md:col-6  lg:col-3 xl:col-3 ">
    <p-card>
      <div class="app-why">
      <img lazyLoadImage [src]="icon_avia_profile_db" />
      <p class="mt-3">
        {{ "PAGE.GLOBAL.WHY.STEP_ONE" | translate }}
      </p>
      </div>

    </p-card>

  </div>
  <div class="col-12 sm:col-6  md:col-6  lg:col-3 xl:col-3 ">
    <p-card>
      <div class="app-why">
    <img lazyLoadImage [src]="icon_avia_check_list" />
    <p class="mt-3">
      {{ "PAGE.GLOBAL.WHY.STEP_TWO" | translate }}
    </p>
      </div>

    </p-card>

  </div>
  <div class="col-12 sm:col-6  md:col-6  lg:col-3 xl:col-3 ">
    <p-card>
      <div class="app-why">
    <img lazyLoadImage [src]="icon_avia_numbers" />
    <p class="mt-3">
      {{ "PAGE.GLOBAL.WHY.STEP_THREE" | translate }}
    </p>
      </div>

    </p-card>

  </div>
  <div class="col-12 sm:col-6  md:col-6  lg:col-3 xl:col-3 ">
    <p-card>
    <div class="app-why">
    <img lazyLoadImage [src]="icon_avia_mail_tickets" />
    <p class="mt-3">
      {{ "PAGE.GLOBAL.WHY.STEP_FOUR" | translate }}
    </p>
    </div>
    </p-card>

  </div>
</div>
