import { Directive,  HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]',
  standalone: true,
})
export class OnlyNumbersDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let allowedKeys = [
      'Backspace', 'Tab', 'End', 'Home', '-','+', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];
    if (allowedKeys.includes(event.key) || this.#isNumeric(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }

  #isNumeric(value: string): boolean {
    return /^\d+$/g.test(value);
  }
}
