import {Inject, Injectable} from '@angular/core';
import {Language} from "@app-core/entities";
import {ChangeCurrency, ChangeLanguage, LayoutState} from "@app-states/layout";
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import { ICurrencySite, IS_FULL_VERSION_TOKEN} from "@app-core/constants";
import {CurrencyEnum} from "@app-core/enums/currency.enum";
import {map} from "rxjs/operators";

@Injectable()
export class LayoutStateService {
  constructor(
    @Inject(IS_FULL_VERSION_TOKEN) public isFullSite: boolean,

  ) {}
  @Select(LayoutState.language)
  public readonly language$!: Observable<Language>;
  @Select(LayoutState.currency)
  public readonly currency$!: Observable<CurrencyEnum>;
  @Select(LayoutState.currencyList)
  public readonly currencyList$!: Observable<ICurrencySite[]>;
 public currencyListBySite$ = this.currencyList$.pipe(
    map((list) => list.filter((currency) => currency.siteList.includes(this.isFullSite ? 'RU' : 'KA' )))
 )
  @Dispatch() public changeLanguage = (language: Language) => new ChangeLanguage(language);
 @Dispatch() public changeCurrency = (currency: CurrencyEnum) => new ChangeCurrency(currency);
}
