import {CommonModule, CurrencyPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputMaskModule} from "@ngneat/input-mask";
import {TranslateModule} from '@ngx-translate/core';
import {PassengersSelectCardComponent, TicketCardComponent} from "@shared/components";
import {NotFoundComponent} from "@shared/components/not-found/not-found.component";
import {PayInfoComponent} from "@shared/components/pay-info/pay-info.component";
import {InputToUpperCaseDirective} from "@shared/directives/input-to-uppercase.directive";
import {IsFullVersionDirective} from "@shared/directives/is-full-version.directive";
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {PanelModule} from 'primeng/panel';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from "primeng/radiobutton";
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from "primeng/selectbutton";
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/tabmenu';
import {ToggleButtonModule} from "primeng/togglebutton";
import {TooltipModule} from "primeng/tooltip";
import {TreeModule} from 'primeng/tree';
import {CustomBannerComponent} from './components/custom-banner/custom-banner.component';
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {ListComponent} from './components/list/list.component';
import {TimerComponent} from './components/timer/timer.component';
import {TouroperatorsListComponent} from './components/touroperators-list/touroperators-list.component';
import {WorkTimeComponent} from './components/work-time/work-time.component';
import {DelayRenderingDirective} from './directives/delay-rendering.directive';
import {HighlightDirective} from './directives/highlight.directive';
import {ScrollStyleDirective} from './directives/scroll-style.directive';
import {CustomAsyncPipe} from './pipes/custom-async.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {HighlightPipe} from './pipes/highlight.pipe';
import {UnixDatePipe} from './pipes/unix-date.pipe';
import { EmptyToNullDirective } from './directives/empty-to-null.directive';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { PrimeCalendarDateMaskDirective } from './directives/prime-calendar-date-mask.directive';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { IfLocaleDirective } from './directives/if-locale.directive';
import { FieldByLocalePipe } from './pipes/field-by-locale.pipe';
import {SiteCurrencyPipe} from "@shared/pipes/site-currency.pipe";
import {OnlyNumbersDirective} from "@shared/components/phone-input/numbers.directive";

export const PIPES = [FilterPipe, CustomAsyncPipe, HighlightPipe, UnixDatePipe,FieldByLocalePipe,SiteCurrencyPipe];

export const DIRECTIVES = [
  HighlightDirective,
  DelayRenderingDirective,
  ScrollStyleDirective,
  EmptyToNullDirective,
  InputToUpperCaseDirective,
  PrimeCalendarDateMaskDirective,
  IfLocaleDirective,
  IsFullVersionDirective
];

const PRIME_MODULES = [
  CardModule,
  MenubarModule,
  DropdownModule,
  AccordionModule,
  TableModule,
  DynamicDialogModule,
  AutoCompleteModule,
  CalendarModule,
  TreeModule,
  ProgressSpinnerModule,
  DialogModule,
  ButtonModule,
  ScrollPanelModule,
  TabMenuModule,
  MenuModule,
  ProgressBarModule,
  PanelModule,
  RadioButtonModule,
  TooltipModule,
  ToggleButtonModule,
  SelectButtonModule
];

@NgModule({
  declarations: [
    WorkTimeComponent,
    ListComponent,
    LanguageSwitcherComponent,
    TouroperatorsListComponent,
    TimerComponent,
    ...PIPES,
    ...DIRECTIVES,
    DelayRenderingDirective,
    CustomBannerComponent,
    EmptyToNullDirective,
    PhoneInputComponent,
    NotFoundComponent,
    TicketCardComponent,
    PrimeCalendarDateMaskDirective,
    PassengersSelectCardComponent,
    PayInfoComponent,
    SuccessModalComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    InputMaskModule,
    TranslateModule.forChild({
      extend: true,
    }),
    ...PRIME_MODULES,
    OnlyNumbersDirective,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ...PRIME_MODULES,
    ...PIPES,
    ...DIRECTIVES,
    WorkTimeComponent,
    ListComponent,
    TimerComponent,
    LanguageSwitcherComponent,
    TouroperatorsListComponent,
    CustomBannerComponent,
    PhoneInputComponent,
    InputMaskModule,
    NotFoundComponent,
    TicketCardComponent,
    PassengersSelectCardComponent,
    PayInfoComponent
  ],
  providers: [DialogService,CurrencyPipe],
})
export class SharedModule {}
