import {BaseModel} from "@app-core/models";

export const SexConst: BaseModel[] = [
  {
    label: 'PERSONAL_DATA.SEX.FEMALE',
    value: 'F',
  },
  {
    label: 'PERSONAL_DATA.SEX.MALE',
    value: 'M',
  },
];
