import {Language} from "@app-core/entities";
import {CurrencyEnum} from "@app-core/enums/currency.enum";

export class SaveForm {
  public static readonly type = '[Layout] Save form';
}
export class ChangeHeader {
  public static readonly type = '[Layout] Change Header';
  constructor(public readonly payload: { header?:string,subheader?:string }) {}
}
export class ChangeLanguage {
  public static readonly type = '[Layout] Change Language';
  constructor(public readonly language: Language) {}
}
export class ChangeLanguageSuccess {
  public static readonly type = '[Layout] Change Language success';
}
export class ChangeCurrency {
  public static readonly type = '[Layout] Change Currency';
  constructor(public readonly currency: CurrencyEnum) {}
}

