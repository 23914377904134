import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-banner',
  templateUrl: './custom-banner.component.html',
  styleUrls: ['./custom-banner.component.scss'],
})
export class CustomBannerComponent implements OnInit {
  @Input()
  visible: boolean = false;
  @Input()
  closeBtn: boolean = false;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  constructor() {}

  public ngOnInit(): void {}

  public visibleChanged = (state: boolean): void =>
    this.visibleChange.emit(state);
  public onClose = (): void => {
    this.visible = false;
    this.visibleChanged(this.visible);
  };
}
