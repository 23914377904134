import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import moment from 'moment';

import {BehaviorSubject, interval, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export const milliSecondsInSecond = 1000;
export const hoursInDay = 24;
export const minutesInHour = 60;
export const secondsInMinute = 60;

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerComponent implements OnInit, OnDestroy {
  private dDate= moment();
  @Input() public set milliseconds(milliseconds: number | undefined) {
    if (milliseconds) {
      this.dDate = moment().add(milliseconds, 'milliseconds');
    }
  }
  @Input() public set seconds(seconds: number | undefined) {
    if (seconds) {
      this.dDate = moment().add(seconds, 'second');
    }
  }
  @Input() public set minutes(minutes: number | undefined) {
    if (minutes) {
      this.dDate = moment().add(minutes, 'minute');
    }
  }
  @Input() public set hours(hours: number | undefined) {
    if (hours) {
      this.dDate = moment().add(hours, 'hour');
    }
  }
  @Input() public set days(days: number | undefined) {
    if (days) {
      this.dDate = moment().add(days, 'day');
    }
  }

  @Input() public format: string = 'HH:mm:ss';

  @Output() public completed: EventEmitter<boolean>;

  public timeDifference: BehaviorSubject<string> = new BehaviorSubject('');
  // 0-59
  public millisecondsToDday: number = 0;
  public secondsToDday: number = 0;
  // 0-59
  public minutesToDday: number = 0;
  // 0-23
  public hoursToDday: number = 0;
  // 1-31
  public daysToDday: number = 1;

  private destroySub: Subject<any>;

  constructor() {
    this.completed = new EventEmitter(false);

    this.destroySub = new Subject();
  }

  public ngOnInit() {
    interval(1000)
      .pipe(takeUntil(this.destroySub))
      .subscribe((_) => {
        this.getTimeDifference();
      });
  }
  public ngOnDestroy() {
    this.unsubscribe();
  }

  private getTimeDifference() {
    const timeDifference = this.dDate.diff(moment());

    if (timeDifference > 0) {
      this.allocateTimeUnits(timeDifference);
    } else {
      this.isCompleted();
    }
  }
  private setTimeDifferenceView() {
    const string = moment({
      days: this.daysToDday,
      hours: this.hoursToDday,
      minutes: this.minutesToDday,
      seconds: this.secondsToDday,
      milliseconds: this.millisecondsToDday,
    }).format(this.format);

    this.timeDifference.next(
      // `${this.daysToDday}:${this.hoursToDday}:${this.minutesToDday}:${this.secondsToDday}`
      string
    );
  }
  private allocateTimeUnits(timeDifference: number) {
    this.millisecondsToDday = Math.floor(
      (timeDifference / milliSecondsInSecond) % secondsInMinute
    );
    this.secondsToDday = Math.floor(
      (timeDifference / milliSecondsInSecond) % secondsInMinute
    );
    this.minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInSecond * minutesInHour)) %
        secondsInMinute
    );
    this.hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInSecond * minutesInHour * secondsInMinute)) %
        hoursInDay
    );
    const daysToDday = Math.floor(
      timeDifference /
        (milliSecondsInSecond * minutesInHour * secondsInMinute * hoursInDay)
    );
    this.daysToDday = daysToDday === 0 ? 1 : daysToDday;
    // this.secondsToDday = moment(timeDifference).seconds();
    // this.minutesToDday = moment(timeDifference).minutes();
    // this.hoursToDday = moment(timeDifference).hours();
    // this.daysToDday = moment(timeDifference).days();

    this.setTimeDifferenceView();
  }
  private unsubscribe() {
    this.destroySub.next(null);
    this.destroySub.complete();
  }
  private isCompleted(): void {
    this.completed.emit(true);
    this.unsubscribe();
  }
}
