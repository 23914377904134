import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {LayoutStateService} from "@app-states/layout-state.service";
import {CurrencyEnum} from "@app-core/enums/currency.enum";
import {CurrencyPipe} from "@angular/common";
import {combineLatest} from "rxjs";
import {ICurrencySite} from "@app-core/constants";

@Pipe({
  name: 'siteCurrency',
  pure: false,
})
export class SiteCurrencyPipe implements PipeTransform {
  private currency: ICurrencySite | undefined;
constructor(private currencyPipe: CurrencyPipe,private readonly layoutStateService: LayoutStateService,private cdr: ChangeDetectorRef) {
 combineLatest([ this.layoutStateService.currency$,this.layoutStateService.currencyList$]).subscribe(([currency,list]) => {
    if (currency && list) {
      this.currency = list.find((item) => item.value === currency);
      this.cdr.markForCheck();
    }
  });
}
  transform(value: Record<CurrencyEnum,number|string|undefined>): string | null {
    const currencyValue = this.currency?.value;
  if (currencyValue && value[currencyValue] !== undefined && value[currencyValue] !== null){
    return this.currencyPipe.transform(value[currencyValue], currencyValue,  "symbol-narrow" , "1.0-2" , this.currency?.locale);
  }
    return '';
  }

}
