<div class="grid grid-nogutter justify-content-center small-space">
  <div
    class="col-8 sm:col-8 md:col-4 lg:col-5 xl:col-5 flex flex-column align-items-center text-center"
  >
    <p class="big-text">{{ "PAGE.SEARCH.NOT_FOUND.TEXT_ONE" | translate }}</p>
    <p>{{ "PAGE.SEARCH.NOT_FOUND.TEXT_TWO" | translate }}</p>
    <button class="btn-primary my-3" [routerLink]="['/']">{{
      "UI.BUTTONS.NEW_SEARCH" | translate
      }}</button>
  </div>
  <div
    class="col-12 sm:col-12 md:col-3 lg:col-3 xl:col-3 flex flex-column align-items-center"
  >
    <img [src]="cat" alt="cat img not found" />
  </div>

</div>
