import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output,} from '@angular/core';
import {LANGUAGES_TOKEN} from "@app-core/constants";
import {Language} from '@app-core/entities';
import {DropdownChangeEvent} from "primeng/dropdown";

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements OnInit {
  @Input()
  public currentLanguage!: Language;
  @Output() public langChanged: EventEmitter<Language>;

  public options = Object.values(this.languages);
  constructor(@Inject(LANGUAGES_TOKEN) private languages: { [locale: string]: Language },) {
    this.langChanged = new EventEmitter(false);

  }

  public ngOnInit(): void {}

  public changeLang(event: DropdownChangeEvent): void {
    const selectedLang = this.languages[event.value];
    selectedLang && this.langChanged.emit(selectedLang);
  }
}
