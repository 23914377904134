import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {LazyLoadImageModule} from "@shared/directives";
import {CardModule} from "primeng/card";
import {WhyComponent} from './why.component';


@NgModule({
  declarations: [WhyComponent],
    imports: [
        LazyLoadImageModule,
        TranslateModule.forChild({
            extend: true,
        }),
        CommonModule,
        CardModule
    ],
  exports: [WhyComponent]
})
export class WhyModule { }
