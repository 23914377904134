import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

const icon_avia_profile_db = '/assets/images/icon_avia_profile_db.svg';
const icon_avia_check_list = '/assets/images/icon_avia_check_list.svg';
const icon_avia_numbers = '/assets/images/icon_avia_numbers.svg';
const icon_avia_mail_tickets = '/assets/images/icon_avia_mail_tickets.svg';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhyComponent implements OnInit {
  public icon_avia_profile_db: string = icon_avia_profile_db;
  public icon_avia_check_list: string = icon_avia_check_list;
  public icon_avia_numbers: string = icon_avia_numbers;
  public icon_avia_mail_tickets: string = icon_avia_mail_tickets;

  constructor() {}

  public ngOnInit(): void {}
}
