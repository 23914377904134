import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-passengers-select-card',
  templateUrl: './passengers-select-card.component.html',
  styleUrls: ['./passengers-select-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassengersSelectCardComponent {

}
