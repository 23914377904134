import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  public transform(
    value: string,
    searchTerm: string,
    styleClass: string = 'autocomplete-highlight'
  ): any {
    if (!searchTerm) {
      return value;
    }

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(regex, `<span class="${styleClass}">$1</span>`);
    // return this.getFormattedText(value, searchTerm);
  }
  private getFormattedText(value: string, searchTerm: string): string {
    const arr = searchTerm.trim().split(' ');
    const regex = new RegExp(`(${arr.join('|')})`, 'gi');
    // console.log(regex);

    return value.replace(
      regex,
      `<span class="autocomplete-highlight">$1</span>`
    );
  }
}
