<div class="inputfield">

<label *ngIf="label" class="label" for="phone">{{ label }}</label>
  <input
    appOnlyNumbers
    class="w-full"
    #phoneControl
    (focus)="phoneControl.placeholder=''"
    id="phone"
    size="large"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (change)="onChangeInput($event)"
    (keyup)="onKeyUp($event)"
  />

</div>
