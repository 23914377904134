import {CLASS_TYPE, SEARCH_FORM_TYPE} from '@app-core/enums';
import {ExternalSearchForm, OtherTicketsState, PreSearchForm,  TicketsState,} from '@app-core/models';
import moment from 'moment';
import {STANDARD_DEPART_DATE} from "@app-core/constants";

export const TICKETS_SHOW_COUNT_STEP = 999;
export const OTHER_TICKETS_SHOW_COUNT_STEP = 3;

export enum PresetAirports {
  Moscow = 448,
  From = 499,
  // Almaty = 499,
  //Antalya = 543
  To = 543,
}

export interface SearchStateModel extends TicketsState {
  searchForm: {
    model: any ;
    dirty: boolean;
    status: string;
    errors: object;
  };
  isConnectionSuccess: boolean;
  dataLoaded: boolean;
  otherTicketsState: OtherTicketsState;
  lastRequestId: number | null;
  // lastSearchTime: Date;
  totalCountOperatorsInLastRequest: number;
  countLoadedOperatorsInLastRequest: number;
  apiKey: string;
  presetSearchForm: PreSearchForm;
  dateTimeCreate: Date |null;
  bookingTimeExpire:Date|null;
  currentServerDateTime:Date|null;
  // airportFromId: number;
  // airportToId: number;
  searchStartOnce: boolean;
  // operatorCards: CustomerSearchProcessedResultData[];
  hash:string;
}

export const DEFAULT_SEARCH_STATE_MODEL: SearchStateModel = {
  searchForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  isConnectionSuccess: false,
  dataLoaded: false,
  tickets:[],
  ticketsToShowCount: TICKETS_SHOW_COUNT_STEP,

  otherTicketsState: {
    show: false,
    tickets: [],
    ticketsToShowCount: OTHER_TICKETS_SHOW_COUNT_STEP,
  },
  lastRequestId: 123,
  // lastSearchTime: new Date(),
  totalCountOperatorsInLastRequest: 0,
  countLoadedOperatorsInLastRequest: 0,
  apiKey: 'dsjhfg8743yrui3ry43uirf',
  // airportFromId: PresetAirports.Moscow,
  // airportToId: PresetAirports.Antalya,
  presetSearchForm:{
    airportFromId: PresetAirports.From,
    airportToId: PresetAirports.To,
    // searchFormType: SEARCH_FORM_TYPE.oneDirection,
    adultCount: 1,
    infantCount: 0,
    classType: CLASS_TYPE.economy,
    departDate: STANDARD_DEPART_DATE,
    returnDate: null
  },
  searchStartOnce: false,
  hash:'',
  dateTimeCreate: null,
  bookingTimeExpire:null,
  currentServerDateTime:null
};
